<template>
  <sb-card :loading="loading">
    <template slot="title">
      <v-card-title>
        <sb-title title="Service Request Details" />
        <v-spacer />
        <v-tooltip v-if="!loading"
                   left>
          <template #activator="{on}">
            <v-chip :color="getStatusColor(serviceRequest.status.key)"
                    label
                    dark
                    tag="div"
                    v-on="on">
              {{ serviceRequest.status.value }}
            </v-chip>
          </template>
          <span>Status</span>
        </v-tooltip>
      </v-card-title>
    </template>

    <sb-empty-message v-if="!serviceRequest.id"
                      message="No Service Request Detail Available" />

    <v-container v-if="!loading && serviceRequest.id">
      <v-row class="mb-5">
        <v-col v-if="serviceRequest.externalReferenceType"
               cols="12"
               sm="6">
          <sb-input label="External Type"
                    :value="serviceRequest.externalReferenceType" />
        </v-col>
        <v-col v-if="serviceRequest.externalReferenceId"
               cols="12"
               sm="6">
          <sb-input label="External Reference"
                    :value="serviceRequest.externalReferenceId" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="Service Type"
                    :value="serviceRequest.serviceType.value" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="Service Request Number"
                    :value="serviceRequest.serviceRequestNumber" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="Request Type"
                    :value="serviceRequest.requestType.value" />
        </v-col>
        <v-col cols="12"
               sm="6">
          <sb-input label="Status"
                    :value="serviceRequest.status.value" />
        </v-col>
      </v-row>
    </v-container>
    <v-expand-transition>
      <div v-show="showMore"
           v-if="serviceRequest">
        <sb-title title="Vehicle Detail" />
        <v-container v-if="!loading">
          <v-row class="mb-5">
            <v-col cols="12"
                   sm="6">
              <sb-input label="Vehicle Registration"
                        :value="serviceRequest.vehicle.registrationNumber" />
            </v-col>
            <v-col cols="12"
                   sm="6">
              <sb-input label="Vehicle Model"
                        :value="serviceRequest.vehicle.model" />
            </v-col>
          </v-row>
        </v-container>
        <sb-title title="Client Detail" />
        <v-container v-if="!loading">
          <v-row class="mb-5">
            <v-col cols="12"
                   sm="6">
              <sb-input label="Client Name"
                        :value="serviceRequest.customer.name" />
            </v-col>
            <v-col cols="12"
                   sm="6">
              <sb-input label="Cost Centre"
                        :value="serviceRequest.customer.costCentre" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-expand-transition>
    <v-row v-if="serviceRequest.id"
           justify="center">
      <v-btn color="secondary"
             large
             outlined
             @click="showMore = !showMore">
        {{ showMore ? 'Show Less' : 'Show More' }}
      </v-btn>
    </v-row>
  </sb-card>
</template>

<script>
import { SR_STATUSES } from '@/config/constants';

export default {
  props: {
    serviceRequest: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showMore: false,
    };
  },

  methods: {
    getStatusColor(key) {
      let color;

      switch (key) {
        case SR_STATUSES.SR_STATUS_CLOSED.value:
        case SR_STATUSES.SR_STATUS_COMPLETED.value:
          color = 'error';
          break;
        default:
          color = 'success';
      }

      return color;
    },
  },
};
</script>
